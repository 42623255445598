import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListViewForSalesUser = _resolveComponent("ListViewForSalesUser")!
  const _component_ListViewForAgentsUser = _resolveComponent("ListViewForAgentsUser")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showListForSalesUser)
      ? (_openBlock(), _createBlock(_component_ListViewForSalesUser, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isAgentUser)
      ? (_openBlock(), _createBlock(_component_ListViewForAgentsUser, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}