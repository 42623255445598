import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_json_pretty = _resolveComponent("vue-json-pretty")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    "is-open": _ctx.isOpen,
    onClose: _ctx.handleClose,
    "modal-width": "70%"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_vue_json_pretty, {
        data: _ctx.jsonData,
        "show-icon": true,
        "show-line-number": true
      }, null, 8, ["data"])
    ]),
    _: 1
  }, 8, ["is-open", "onClose"]))
}