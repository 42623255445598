import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardGreetingsCard = _resolveComponent("DashboardGreetingsCard")!
  const _component_SalesMyTeam = _resolveComponent("SalesMyTeam")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardGreetingsCard, { user: "Sales User" }),
    _createVNode(_component_SalesMyTeam)
  ], 64))
}