import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgentDashboardView = _resolveComponent("AgentDashboardView")!
  const _component_SuperUserDashboardView = _resolveComponent("SuperUserDashboardView")!
  const _component_OperationUserDashboardView = _resolveComponent("OperationUserDashboardView")!
  const _component_FinanceUserDashboardView = _resolveComponent("FinanceUserDashboardView")!
  const _component_SalesUserDashboardView = _resolveComponent("SalesUserDashboardView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isUserAgent())
      ? (_openBlock(), _createBlock(_component_AgentDashboardView, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isSuperUser())
      ? (_openBlock(), _createBlock(_component_SuperUserDashboardView, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.isOperationUser())
      ? (_openBlock(), _createBlock(_component_OperationUserDashboardView, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.isFinanceUser())
      ? (_openBlock(), _createBlock(_component_FinanceUserDashboardView, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.isSalesUser())
      ? (_openBlock(), _createBlock(_component_SalesUserDashboardView, { key: 4 }))
      : _createCommentVNode("", true)
  ], 64))
}