import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgBookingExpiryNotification = _resolveComponent("AgBookingExpiryNotification")!
  const _component_ag_div = _resolveComponent("ag-div")!

  return (_ctx.expiryCount)
    ? (_openBlock(), _createBlock(_component_ag_div, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_AgBookingExpiryNotification, { bookingCount: _ctx.expiryCount }, {
            buttonAction: _withCtx(() => [
              _createVNode(_component_AGButton, {
                variant: "warning",
                onClick: _ctx.onClickViewBookings,
                class: "view_booking_yellow_btn"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("View Booking")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["bookingCount"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}