import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    "is-open": _ctx.isOpen,
    onClose: _ctx.onCloseModal,
    "modal-width": "40%"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_AgHeading, {
        title: "Delete Agent",
        variant: "h3",
        "test-id": ""
      }),
      _createVNode(_component_AgDiv, null, {
        default: _withCtx(() => [
          _createTextVNode("Are you sure you want to delete "),
          _createElementVNode("b", null, _toDisplayString(_ctx.agent?.user.email), 1),
          _createTextVNode("?")
        ]),
        _: 1
      }),
      _createVNode(_component_AgDiv, { class: "btn_container" }, {
        default: _withCtx(() => [
          _createVNode(_component_AGButton, {
            class: "reject_btn",
            "test-id": "",
            onClick: _ctx.onCloseModal,
            variant: "danger",
            type: "button"
          }, {
            default: _withCtx(() => [
              _createTextVNode("No ")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_AGButton, {
            disbaled: _ctx.$store.getters.isDeletingAgentFromOrganization,
            "is-loading": _ctx.$store.getters.isDeletingAgentFromOrganization,
            "test-id": "",
            onClick: _ctx.onDelete,
            variant: "primary",
            type: "button"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Yes")
            ]),
            _: 1
          }, 8, ["disbaled", "is-loading", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open", "onClose"]))
}